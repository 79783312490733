import {NavLink} from "react-router-dom"


export default function LandingPage(){
    return(
        <>
        
        <div className="container d-flex h-75 align-items-center justify-content-center w-75 text-center flex-column">
            
            
            <div className="landingPage-content">
                <p className="display-1">Hello, I'm Adil</p>
                <p className="display-6">A Web Developer Specialized In Front End Technologies</p>
                
            </div>
            
            
            
            
        </div>
        </>
        
    )
}